import {
  withValidation,
  composeSDKFactories,
} from '@wix/editor-elements-corvid-utils';
import {
  changePropsSDKFactory,
  clickPropsSDKFactory,
  collapsedPropsSDKFactory,
  createRequiredPropsSDKFactory,
  createValidationPropsSDKFactory,
  disablePropsSDKFactory,
  elementPropsSDKFactory,
  focusPropsSDKFactory,
  hiddenPropsSDKFactory,
} from '../../../core/corvid/props-factories';
import {
  IRatingsInputProps,
  IRatingsInputImperativeActions,
  IRatingsInputSDK,
  IRatingsInputOwnSDKFactory,
  IconMouseInEventHandler,
} from '../RatingsInput.types';
import {
  composeValidators,
  createInputValidator,
  InputValidator,
  validateRequiredNumberValue,
} from '../../../core/corvid/inputUtils';

const ratingsInputValidator: InputValidator<
  IRatingsInputProps,
  IRatingsInputImperativeActions
> = createInputValidator(
  composeValidators<IRatingsInputProps>([validateRequiredNumberValue]),
);

const requiredPropsSDKFactory = createRequiredPropsSDKFactory(
  ratingsInputValidator,
);

const validationPropsSDKFactory = createValidationPropsSDKFactory(
  ratingsInputValidator,
);

const _ratingsInputPropsSKDFactory: IRatingsInputOwnSDKFactory = api => {
  const { setProps, props, registerEvent } = api;

  return {
    get value() {
      return props.value;
    },
    get type() {
      return '$w.RatingsInput';
    },
    set value(value) {
      setProps({ value: value || null });

      ratingsInputValidator.validate({
        viewerSdkAPI: api,
        showValidityIndication: true,
      });
    },

    onIconMouseIn(handler: IconMouseInEventHandler) {
      registerEvent('iconMouseIn', handler);
    },
  };
};

const ratingsInputPropsSKDFactory = withValidation(
  _ratingsInputPropsSKDFactory,
  {
    type: ['object'],
    properties: {
      value: {
        type: ['integer', 'nil'],
        maximum: 5,
        minimum: 1,
      },
      onIconMouseIn: {
        type: ['function'],
        args: [{ type: ['function'] }],
      },
    },
  },
);

export const sdk = composeSDKFactories<IRatingsInputProps, IRatingsInputSDK>(
  requiredPropsSDKFactory,
  validationPropsSDKFactory,
  collapsedPropsSDKFactory,
  elementPropsSDKFactory,
  disablePropsSDKFactory,
  hiddenPropsSDKFactory,
  focusPropsSDKFactory,
  changePropsSDKFactory,
  clickPropsSDKFactory,
  ratingsInputPropsSKDFactory,
);
